import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';

const TeamTemplate = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { title, name, linkedin, twitter, mugshot } = frontmatter;

  return (
    <Layout>
      <SEO title={name} />
      <div className="bg-gray-300 rounded rounded-t-lg overflow-hidden shadow max-w-sm md:max-w-lg my-3 m-auto">
        <img src="https://i.imgur.com/dYcYQ7E.png" className="w-full" alt="" />
        <div className="flex justify-center -mt-8">
          <Img
            fixed={mugshot.childImageSharp.fixed}
            alt=""
            className="rounded-full border-solid border-gray-200 border-2 -mt-3"
          />
        </div>
        <div className="text-center px-3 pb-6 pt-2">
          <h3 className="text-black text-xl font-display pb-6 uppercase tracking-widest">
            {name}
          </h3>
          <h4 className="text-black text-xl font-display pb-3">{title}</h4>
          <div
            className="profile-md p-6 leading-relaxed"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div className="flex justify-center pb-3 text-grey-dark">
          { linkedin && <a href={linkedin}
                target="_blank"
                rel='noreferrer noopener'
                aria-label="View the team member LinkedIn account">
            <FaLinkedin className="inline-block m-2 text-black text-3xl" />
          </a>
          }
          { twitter && <a href={twitter}
                target="_blank"
                rel='noreferrer noopener'
                aria-label="View the team member twitter account">
            <FaTwitter className="inline-block m-2 text-black text-3xl" />
          </a>
          }
        </div>
      </div>
    </Layout>
  );
};

export default TeamTemplate;

export const query = graphql`
query ($slug: String!) {
  site {
    siteMetadata {
      title
    }
  }
  markdownRemark(fields: {slug: {eq: $slug}}) {
    id
    excerpt
    html
    frontmatter {
      name
      title
      email
      mobile
      twitter
      linkedin
      homepage
      mugshot {
        childImageSharp {
          fixed(width: 120, height: 128) {
            ...GatsbyImageSharpFixed
          } 
        }
      }
    }
  }
}
`;
